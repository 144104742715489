@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family:Poppins,sans-serif !important;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
html,
body {
    font-family: 'Poppins', sans-serif !important;
    font-size: 15px;
    line-height: 20px;
    min-width: 320px;
    color: var(--darkText);
    background-color: var(--bodyBg);
    -ms-overflow-style: scrollbar;
}
a {
    color: #000;
    text-decoration: none;
}
::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}
::-webkit-scrollbar-thumb {
    border: 0 !important;
    background-color: #b2b2b2 !important;
    border-radius: 12px !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.loader-wrapper{ position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 9999; background: rgba(0,0,0,0.4); display: flex; align-items: center; justify-content: center; }

ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
.hover:hover {
    cursor: pointer;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
ul {
    list-style-type: none;
}
* {
    outline: none !important;
}

.main-content {
    transition: all 0.3s ease-in-out;
}

.diff-viewer table td pre{
    white-space: pre-wrap;
    word-break: break-all;
    overflow-wrap: break-word;
}
.backGround-top {
    background-image: url("../images/Mr_bean_.png");
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    opacity: 0.5;
    pointer-events: none;
}
.backGround-bottom {
    background-image: url("../images/mr_bean_1.png");
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    opacity: 0.5;
    pointer-events: none;
}
.backGround-header {
    background-image: url("../images/mr_bean_dhi.png");
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    opacity: 0.5;
    pointer-events: none;
}
.backGround-top {
    background-size: 150px; /* Set a small size for the image */
    width: 200px;
    height: 210px;
    /* transform: rotate(45deg); */
    position: fixed;
    left: 80%;
    top: -1%;
}
.backGround-bottom{
    background-size: 200px; /* Set a small size for the image */
    width: 200px;
    height: 210px;
    /* transform: rotate(45deg); */
    position: fixed;
    left: 3%;
    top: 72%;
}
.backGround-header {
    background-size: 150px; 
    width: 200px;
    height: 210px;
    /* transform: rotate(45deg); */
    position: fixed;
    left: 15%;
    top: -5%;
}
.MuiChip-label{font-weight: 700;}
.MuiTypography-root{font-family:Poppins,sans-serif !important;}

.MuiCircularProgress-colorPrimary{color: #336699 !important;}
.white-box {
    background-color: var(--whiteBg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
}
.unit-main-wrapper{
    margin-bottom: 20px;
    width: 70% !important;
    margin: 0 auto !important; 
}
.converter-card{background-color:#FFFFFF;border:1px solid #DFE1E3;border-radius:6px;margin-bottom:24px; overflow:hidden;}
.converter-card .converter-card-header{display:flex;align-items:center;justify-content:space-between;padding:12px 20px;background-color:#F3F5F7;}
.converter-card .converter-card-body{padding:20px;}

.router-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color:#336699;
    transition: all 0.3s ease-out;
    position: relative;
}
.router-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 1px;
    transition: width 0.3s ease-out;
    background-color: currentColor;
}
.router-link:hover::after,
.router-link:focus::after {
    width: 100%;
}

@keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideInOut {
    0% {
      transform: translate(0, 80px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
.fade-animation{
    animation: fadeInOut 600ms ease-in-out, slideInOut 600ms ease-in-out;
}

@media (min-width: 600px) {
    .main-content {
        padding: 130px 30px 20px 30px;
    }
    .unit-main-wrapper{width:90% im4 !important;}
}
@media (max-width: 599.98px) {
    .main-content {
        padding: 130px 10px 20px;
    }
    .backGround-top{
        width: 175px;
        height: 210px;
        left: 50%;
        top: -5%;
    }
    .cm-to-inches-wrapper{width: 90%;}
    .unit-main-wrapper{width:90% !important;}


}

@media (min-width: 900px) {
    .main-content {
        padding: 140px 33px 40px 33px;
    }
    .cm-to-inches-wrapper{width: 70%;}
    .unit-main-wrapper{width:80% !important;}

}
@media (min-width: 1200px) {
    .main-content {
        padding: 140px 33px 40px 33px;
    }
    .cm-to-inches-wrapper{width: 70%;}
    .unit-main-wrapper{width:70% !important;}

}